import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import Currency from "react-currency-formatter";

import ReactWhatsapp from "react-whatsapp";
const AppPlan = ({ select }) => {
  const plansWeb = [
    {
      id: 1,
      title: "Basic",
      title2: "SMALL APPS",

      description:
        "Basic Application with up to 5 screens and Firebase Authentication.",
      price: 160000,
      price2: 2000,

      point1: "Functional Android app",
      point2: "Functional IOS App",
      point3: "App submission",
      point4: "App icon",
      point5: "Splash screen",
      point6: "Number of Screens 5",
      point7: "Include source code",
      point8: "Payment Gateway",
      point9: "1 Month Maintance ",
      point10: "Admin pannel",
      point11: "Push Notifications",
      point12: "Online Order Shipping",
      point13: "Ship Rocket",
      message: `Hi, My [Your Name] I'm interested in the Basic Application ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: false,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      active8: true,
      active9: true,
      active10: true,
      active11: true,
      active12: false,
      active13: false,

    },
    {
      id: 2,
      title: "STANDARD",
      title2: "Average App",
      description:
        "Standard Moderately Complex Application with up to 10 screens.",
      price: 220000,
      price2: 2640,
      point1: "Functional Android app",
      point2: "Functional IOS App",
      point3: "App submission",
      point4: "App icon",
      point5: "Splash screen",
      point6: "Number of Screens 10",
      point7: "Include source code",
      point8: "Payment Gateway",
      point9: "2 Month Maintance ",
      point10: "Admin pannel",
      point11: "Push Notifications",
      point12: "Online Order Shipping",
      point13: "Ship Rocket",
      message: `Hi, My [Your Name] I'm interested in the Standard Application ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      active8: true,
      active9: true,
      active10: true,
      active11: true,
      active12: false,
      active13: false,
    },
    {
      id: 3,
      title: "PREMIUM",
      title2: "Professional App",
      description:
        "Premium Functional Mobile Application with Backend , up to 15 screens.",
      price: 290000,
      price2: 3500,
      point1: "Functional Android app",
      point2: "Functional IOS App",
      point3: "App submission",
      point4: "App icon",
      point5: "Splash screen",
      point6: "Number of Screens 15",
      point7: "Include source code",
      point8: "Payment Gateway",
      point9: "3 Month Maintance ",
      point10: "Admin pannel",
      point11: "Push Notifications",
      point12: "Online Order Shipping",
      point13: "Ship Rocket",
      message: `Hi, My [Your Name] I'm interested in the Premium Application ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      active8: true,
      active9: true,
      active10: true,
      active11: true,
      active12: true,
      active13: true,
    },
  ];

  return (
    <div className="service_list">
      <div className="web-title-1">
        <p>Find the best plan for your Mobile App.</p>
      </div>

      <div className="plans-sectionweb plan_mb">
        {plansWeb.map((item, index) => (
          <div
            key={index}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={item.id === 1 ? "" : "200"}
            className="card-1-web"
          >
            <div className="title-2card">
              <h1>{item.title2}</h1>
            </div>
            <h1 className="card-web-h1">{item.title}</h1>

            <h1 className="plans-price">
              {select === "INR" ? (
                <Currency quantity={item?.price} currency="INR" />
              ) : (
                <Currency quantity={item?.price2} currency="USD" />
              )}
            </h1>
            <p className="package-para">{item.description}</p>

            <div className="point-offer-plan">
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active1 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point1}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active2 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point2}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active3 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point3}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active4 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point4}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active5 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point5}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active6 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point6}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active7 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point7}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active8 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point8}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active9 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point9}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active10 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point10}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active11 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point11}</h1>
              </div>


              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active12 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point12}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active13 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point13}</h1>
              </div>
            </div>
            {/* 
            <div>
              <ul style={{ margin: 0 }}>
                <li style={{ margin: 0, padding: 0 }} className="li-plans1">
                  <div className="list_inner2">
                    <div className="learn_more more-d1">
                      More details <span></span>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}
            <div></div>
            <div></div>

            <div className="btn-plan1">
              <ReactWhatsapp number={"+91 7018548021"} message={item.message}>
                Get started
              </ReactWhatsapp>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppPlan;
