import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderParallaxTyper";
import About from "../../components/about/AboutParallaxTyper";
import Service from "../../components/service/ServiceAnimation";
import Portfolio from "../../components/portfolio/CreativePortfolio";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/Contact";
import Footer from "../../components/footer/Footer";
import Address from "../../components/Address";
import PlansWeb from "../../components/service/Plans";
import Services2 from "../../components/service/Services2";

const HomeParallaxTyper = () => {
  document.body.classList.add("light");
  return (
    <div className="home-light">
      <div
        data-aos="fade-left"
        data-aos-duration="1200"
        data-aos-delay="200"
        className="whatsapp-icon"
      >
        <a
          target="blank"
          href="https://wa.me/+917018548021?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for website and mobile app development.?"
        >
          <img src="img/ak/whatsapp.png" alt="" />
        </a>
      </div>
      {/* demo show wrapper  */}

      <Header />
      {/* End Header */}
      <div className="home-d1  " id="home">
        <div className="container">
          <Slider />
        </div>
      </div>

      {/* End Slider */}

      {/* /ABOUT */}

      {/* SERVICES */}
      <div className="beny_tm_services" id="service">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>Services</span>
            <h2>Empowering Your Digital Success Journey</h2>
            <p>
              We offer a diverse portfolio of digital services to meet your
              business needs. From web development and mobile app creation to a
              wide array of digital solutions, my expertise spans across various
              domains. Whether you require custom software development,
              e-commerce solutions, digital consulting, or other digital
              services, We have the skills and experience to bring your ideas to
              life in the digital landscape.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Service />
        </div>
      </div>

      <div className="beny_tm_services" id="plans">
        <div className="container">
          <PlansWeb />
        </div>
      </div>

      {/* /SERVICES */}

      {/* PORTFOLIO */}
      <div className="beny_tm_portfolio" id="portfolio">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>Projects</span>
            <h2>Professional Experience</h2>
            <p>
              Proven track record of delivering robust and scalable web and
              mobile applications, combining technical expertise with a
              user-centric approach to meet and surpass project goals.
            </p>
          </div>

          <Portfolio />
        </div>
      </div>

      {/*  CONTACT */}
      <div className="beny_tm_contact" id="contact">
        <div className="container">
          <div className="contact_inner">
            <div
              className="left"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="beny_tm_title_holder">
                <span>Contact</span>
                <h2>Let's discuss your project</h2>
              </div>
              <div className="short_list">
                <Address />
              </div>
            </div>
            {/* End .left */}

            <div
              className="right"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <div className="title">
                <p>
                  We always open to discussing product
                  <br />
                  <span>design work or partnerships.</span>
                </p>
              </div>
              <div className="fields">
                <Contact />
              </div>
            </div>
          </div>
          {/* <div className="map-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109741.03747902173!2d76.6809562461318!3d30.735055312378545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0be66ec96b%3A0xa5ff67f9527319fe!2sChandigarh!5e0!3m2!1sen!2sin!4v1697994108756!5m2!1sen!2sin"
              loading="lazy"
            ></iframe>
          </div> */}
        </div>
      </div>
      {/* /CONTACT */}

      {/* COPYRIGHT */}
      <div className="beny_tm_copyright">
        <div className="container">
          <Footer />
        </div>
      </div>
      {/* /COPYRIGHT */}
    </div>
  );
};

export default HomeParallaxTyper;
